<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.order_ID')">
                <el-input
                  v-model="formInline.payOrderId"
                  :onkeyup="
                    (formInline.payOrderId = formInline.payOrderId.replace(
                      /[^\d]/g,
                      ''
                    ))
                  "
                  placeholder="请输入订单ID"
                  maxlength="19"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="
                    formInline.streetId
                      ? formInline.streetId
                      : formInline.areaId
                  "
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  :dataSource="1"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="formInline.plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <!-- <el-form-item :label="$t('searchModule.Payment_method')">
            <el-select v-model.trim="formInline.payType"
                       filterable
                       placeholder=""
                       size="12">
              <el-option label="全部"
                         value=""></el-option>
              <el-option :label="pay.payTypeName"
                         :value="pay.payType"
                         :key="key"
                         v-for="(pay, key) in payTypeList"></el-option>
            </el-select>
          </el-form-item> -->
              <el-form-item :label="$t('searchModule.Payment_channels')">
                <el-select v-model="formInline.channelType">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="area.channelTypeDesc"
                    :value="area.channelType"
                    :key="area.channelType"
                    v-for="area in payList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Payment_devices')">
                <el-select
                  v-model.trim="formInline.devType"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code + ''"
                    :key="value.code"
                    v-for="value in exqList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Operator')">
                <el-input
                  v-model="formInline.operatorName"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable>
                  <!-- <el-option label="全部"
                         value=""></el-option> -->
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  placeholder="泊位号"
                  maxlength="30"
                  v-model="formInline.berthCode"
                ></el-input>
              </el-form-item>
              <br />
              <el-form-item :label="$t('searchModule.Time_type')" class="time_item">
                <el-select
                  v-model.trim="formInline.timeConditionType"
                  filterable
                  style="max-width: 120px"
                >
                  <el-option :label="$t('searchModule.Payment_Time')" value=""></el-option>
                  <el-option label="计费时间" value="0"></el-option>
                  <el-option :label="$t('searchModule.Entry_time')" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <timeRangePick
                  v-if="isviftime"
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defaultRange="defaultRangeValue"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
              <el-button type="info" icon="el-icon-delete" @click="resetForm()"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <!-- 导出或下载按钮 -->
              <exportFile
                style="display: inline-block"
                :exportData="exportData"
                v-if="$route.meta.authority.button.export"
              ></exportFile>
            </div>
          </div>
          <tabCard :cardLists="cardLists"></tabCard>
        </el-form>
      </div>
      <!-- <div class="total-wrapper">
        <div class="item-wrapper">
          <div class="text">支付记录</div>
          <span class="unit"
            ><span class="bold-wrap">{{ totalObj.orderNum || 0 }}</span
            >个</span
          >
        </div>
        <div class="item-wrapper">
          <div class="text">应收金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.shouldPayMoney
                  ? totalObj.shouldPayMoney.toString().length > 8
                    ? (totalObj.shouldPayMoney / 100 / 10000).toFixed(2)
                    : (totalObj.shouldPayMoney / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{
              totalObj.shouldPayMoney && totalObj.shouldPayMoney.toString().length > 8
                ? "万元"
                : "元"
            }}
          </span>
        </div>
        <div class="item-wrapper">
          <div class="text">优惠金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.couponMoney
                  ? totalObj.couponMoney.toString().length > 8
                    ? (totalObj.couponMoney / 100 / 10000).toFixed(2)
                    : (totalObj.couponMoney / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{ totalObj.couponMoney && totalObj.couponMoney.toString().length > 8 ? "万元" : "元" }}
          </span>
        </div>
        <div class="item-wrapper">
          <div class="text">实付金额</div>
          <span class="unit">
            <span class="bold-wrap">
              {{
                totalObj.money
                  ? totalObj.money.toString().length > 8
                    ? (totalObj.money / 100 / 10000).toFixed(2)
                    : (totalObj.money / 100).toFixed(2)
                  : "0.00"
              }}
            </span>
            {{ totalObj.money && totalObj.money.toString().length > 8 ? "万元" : "元" }}
          </span>
        </div>
      </div> -->
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="item.prop == 'money'">
                <b v-if="scope.row.isPreRecord == 1" class="orderNumberStyle">{{
                  $t("list.in_advance")
                }}</b>
                {{
                  scope.row.money
                    ? Number(scope.row.money / 100).toFixed(2)
                    : "0.00"
                }}
              </span>
              <span v-else>
                <span>
                  {{
                    item.formatter
                      ? item.formatter(scope.row)
                      : scope.row[item.prop]
                  }}
                </span>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import myComponent from "@/components/autocomplete/myautoComponent";
import { dateFormat, setIndex } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";
import tabCard from "@/components/tabCard/index.vue";
export default {
  name: "payRecordQuery",
  data() {
    return {
      cardLists: [
        { title: "支付记录", number: 0, unit: "个" },
        { title: "应收金额", number: 0, unit: "元" },
        { title: "优惠金额", number: 0, unit: "元" },
        { title: "实付金额", number: 0, unit: "元" },
      ],
      tenantList: [],
      defalutDate: [],
      payList: [],
      exqList: [],
      options: [
        {
          value: 1,
          label: this.$t("list.entry_time"),
        },
        {
          value: 0,
          label: this.$t("list.Appearance_time"),
        },
      ],
      index: 0,
      total: 0,
      pageSize: 15,
      plateNumber: "",
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      berthList: [],
      payTypeList: [],
      tableCols: [
        {
          prop: "payOrderId",
          label: this.$t("list.order_ID"),
          width: "180",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "berthNumber",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "entryTime",
          label: this.$t("list.entry_time"),
          width: "180",
        },
        {
          prop: "exitTime",
          label: this.$t("list.billable_hours"),
          width: "180",
        },
        {
          prop: "strParkTime",
          label: this.$t("list.Parking_duration"),
          width: "",
        },
        {
          prop: "shouldPay",
          label: this.$t("list.amount_receivable"),
          width: "",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "agioPay",
          label: this.$t("list.preferential_amount"),
          width: "",
          formatter: (row, column) => {
            if (row.agioPay) {
              return Number(row.agioPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "money",
          label: this.$t("list.Pay_amount"),
          width: "",
          // formatter: (row, column) => {
          //   if (row.isPreRecord == 1) {
          //     return "预 " + Number(row.money / 100).toFixed(2);
          //   } else {
          //     return "0.00";
          //   }
          // },
        },
        //  {
        //   prop: 'payTypeDesc',
        //   label: '支付方式',
        //   width: ''
        // },
        {
          prop: "channelTypeDesc",
          label: this.$t("list.Payment_channels"),
        },
        {
          prop: "devTypeDesc",
          label: this.$t("list.Payment_devices"),
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "payParkName",
          label: this.$t("list.toll_road"),
          width: "",
        },
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "180",
        },
        {
          prop: "tradeNo",
          label: this.$t("list.Third_party_transaction_ID"),
          width: "270",
        },
      ],
      tableData: [],
      formInline: {
        timeConditionType: "", // 0表示出场时间,1表示入场时间，不传表示支付时间
        payOrderId: "", // 订单ID
        berthCode: "",
        operationId: "",
        parkId: "",
        parkName: "",
        payType: "",
        carId: "",
        startTime: "",
        endTime: "",
        channelType: "",
        payList: "",
        // timeConditionType: 1
        devType: "",
        dataSource: 1,
        name: `停车支付订单`,
        operatorName: "",
      },
      exportData: {},
      isviftime: true,
      totalObj: {},
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
    $route(to, from) {
      if (this.$route.params.pdaManagerName) {
        this.defalutDate = [
          this.$route.params.startTime,
          this.$route.params.endTime,
        ];
        this.formInline.startTime = this.$route.params.startTime;
        this.formInline.endTime = this.$route.params.endTime;
        this.formInline.operatorName = this.$route.params.pdaManagerName;
        this.searchData();
      }
    },
  },
  computed: {
    defaultRangeValue() {
      return [
        {
          typeNumber: this.formInline.plateNumber,
          warntip: "请输入您查询的车牌号",
        },
        {
          typeNumber: this.formInline.operatorName,
          warntip: "请输入您查询的操作员",
        },
      ];
    },
  },
  methods: {
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr, shortcutKey) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
      if (shortcutKey) {
        this.searchData();
      }
    },
    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
    },
    resetForm() {
      this.plateNumber = "";
      this.formInline = {
        payOrderId: "", // 订单ID
        berthCode: "",
        timeConditionType: "",
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "1",
        plateNumber: "",
        entryOrExit: 1,
        startTime: "",
        endTime: "",
        mobile: "",
        // timeConditionType: 1,
        payType: "",
        channelType: "",
        devType: "",
        dataSource: 1,
        name: `停车支付订单`,
        operatorName: "",
      };
      this.$refs.timeRangePicker.resetTime();
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.parkInput.setValue();
      this.exportData = {
        url: "/acb/2.0/payOrder/payOrderList/export",
        methods: "post",
        data: this.formInline,
      };
      this.pageNum = 1;
      this.searchData();
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        // let time =
        //   new Date(this.formInline.endTime) -
        //   new Date(this.formInline.startTime);
        // time = time / (24 * 60 * 60 * 1000);
        // if (time >= 31 && (!this.formInline.operatorName && !this.formInline.plateNumber)) {
        //   this.$alert("查询时间不能大于31天");
        //   return false;
        // } else {
        return true;
        // }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 获取支付方式
    getPay() {
      let opt = {
        method: "get",
        url: "/acb/2.0/payment/payTypeDictList",
        success: (res) => {
          if (res.state == 0) {
            this.payTypeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },

    /**
     * 统计结果
     */
    getTotalList(data) {
      let opt = {
        method: "get",
        url: "/acb/2.0/payOrder/total",
        data,
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            console.log(res.value, "请求金额");
            this.totalObj = res.value || {};
            this.cardLists[0].number = res.value.orderNum || 0;
            this.cardLists[1].number = res.value.shouldPayMoney
              ? res.value.shouldPayMoney.toString().length > 8
                ? (res.value.shouldPayMoney / 100 / 10000).toFixed(2)
                : (res.value.shouldPayMoney / 100).toFixed(2)
              : "0.00";
            this.cardLists[1].unit =
              res.value.shouldPayMoney &&
              res.value.shouldPayMoney.toString().length > 8
                ? "万元"
                : "元";
            this.cardLists[2].number = res.value.couponMoney
              ? res.value.couponMoney.toString().length > 8
                ? (res.value.couponMoney / 100 / 10000).toFixed(2)
                : (res.value.couponMoney / 100).toFixed(2)
              : "0.00";
            this.cardLists[2].unit =
              res.value.couponMoney &&
              res.value.couponMoney.toString().length > 8
                ? "万元"
                : "元";
            this.cardLists[3].number = res.value.money
              ? res.value.money.toString().length > 8
                ? (res.value.money / 100 / 10000).toFixed(2)
                : (res.value.money / 100).toFixed(2)
              : "0.00";
            this.cardLists[3].unit =
              res.value.money && res.value.money.toString().length > 8
                ? "万元"
                : "元";
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (res) => {
          this.totalObj = {};
        },
      };
      this.$request(opt);
    },

    searchData() {
      let flag = this.showLog();
      let opt = {
        method: "get",
        url: "/acb/2.0/payOrder/payOrderList",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          payOrderId: this.formInline.payOrderId,
          operationId: this.formInline.operationId,
          berthCode: this.formInline.berthCode,
          payType: this.formInline.payType,
          timeConditionType: this.formInline.timeConditionType,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          parkId: this.formInline.parkId,
          carId: this.formInline.carId,
          // timeConditionType: this.formInline.timeConditionType
          channelType: this.formInline.channelType,
          devType: this.formInline.devType,
          dataSource: this.formInline.dataSource,
          operatorName: this.formInline.operatorName, // 操作人
        },
        success: (res) => {
          this.tableData = [];
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
            this.getTotalList(opt.data);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
        fail: (res) => {
          this.tableData = [];
          this.totalObj = {};
          this.loading = false;
        },
      };
      if (flag) {
        this.loading = true;
        this.$request(opt);
      }
    },
    getExq(data) {
      this.$axios
        .get("/acb/2.0/payOrder/devTypeList")
        .then((res) => {
          // console.log("测试", res)
          this.exqList = res.value;
        })
        .catch(() => {
          this.exqList = [];
        });
    },
    getPayType(data) {
      this.$axios
        .get("/acb/2.0/payOrder/payChannelTypeDictList")
        .then((res) => {
          // console.log("测试", res)
          this.payList = res.value;
        })
        .catch(() => {
          this.payList = [];
        });
    },
  },
  components: {
    tabCard,
    myComponent,
    exportFile,
    timeRangePick,
  },
  activated() {},
  created() {
    if (this.$route.params.pdaManagerName) {
      this.defalutDate = [
        this.$route.params.startTime,
        this.$route.params.endTime,
      ];
      this.formInline.startTime = this.$route.params.startTime;
      this.formInline.endTime = this.$route.params.endTime;
      this.formInline.operatorName = this.$route.params.pdaManagerName;
      this.searchData();
    }
    this.getTenantList();
  },
  mounted() {
    this.searchData();
    this.getExq();
    this.getPayType();
    this.getPay();

    // 导出数据
    this.exportData = {
      url: "/acb/2.0/payOrder/payOrderList/export",
      methods: "post",
      data: this.formInline,
    };
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
::v-deep .el-form-item.time_item {
  min-width: 0px;

  .el-input__inner {
    margin-top: 1px;
    min-width: 243px;
    max-width: 243px;
  }

  .el-input {
    min-width: 243px;
    max-width: 243px;
  }
}

.orderNumberStyle {
  margin-right: 2px;
  padding: 3px 5px;
  color: #fff;
  background: #95AEFF;
  border-radius: 4px;
  font-size: 12px;
}

.content {
  overflow: hidden;

  // background: #fff;
  .searchWrapper {
    overflow: hidden;
    // padding: 22px 22px 0px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.tableWrapper {
  // padding: 0 20px 20px;
}

.total-wrapper {
  padding: 0 20px;
  margin-bottom: 22px;

  .item-wrapper {
    min-width: 88px;
    margin-bottom: 5px;
    margin-right: 16px;
    padding: 6px 16px;
    display: inline-block;
    background: #F4F8FF;
    border-radius: 4px;
  }

  .text {
    line-height: 17px;
    color: #425466;
    font-size: 12px;
  }

  .bold-wrap {
    padding-right: 2px;
    position: relative;
    top: 1px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0f6eff;
    line-height: 25px;
  }

  .unit {
    color: #6C7293;
    font-size: 12px;
  }
}
</style>
